import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createVNode(_component_el_table, {
      data: _ctx.table,
      stripe: "",
      "empty-text": "没有记录",
      style: {"width":"100%"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          type: "index",
          label: "序号",
          width: "50"
        }),
        _createVNode(_component_el_table_column, {
          prop: "name",
          label: "公司名称"
        }),
        _createVNode(_component_el_table_column, {
          prop: "creditCode",
          label: "统一社会信用代码"
        }),
        _createVNode(_component_el_table_column, {
          prop: "state",
          label: "黑名单"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("span", null, _toDisplayString(scope.row.state == 0?'是':'否'), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "createDate",
          label: "拉入时间"
        }),
        _createVNode(_component_el_table_column, {
          prop: "blacklistReason",
          label: "黑名单原因"
        })
      ]),
      _: 1
    }, 8, ["data"]), [
      [_directive_loading, _ctx.loading]
    ])
  ]))
}